import axios from "axios";
import configData from "../config"
// import { useParams } from "react-router-dom";
// import { Navigate } from "react-router-dom";
// import React from "react";

const checkIfAuthenticated = ( currentLocation ) => {
	let authRoute = [
		`${configData.ADMIN_PREFIX}/login`,
		`${configData.MEMBER_PREFIX}/login`,
		`${configData.ADMIN_PREFIX}/forgot-password`,
		`${configData.ADMIN_PREFIX}/password/reset/:token`,
	];

	if( currentLocation.pathname.match(/password\/reset/g) && localStorage.getItem( "authToken" ) != null && localStorage.getItem( "user" ) != null){
		return true;
	}
	if( authRoute.includes( currentLocation.pathname ) && localStorage.getItem( "authToken" ) != null && localStorage.getItem( "user" ) != null ){
		return true;
	}

	if( !authRoute.includes( currentLocation.pathname ) && localStorage.getItem( "authToken" ) != null ){
		return true;
	}
	else{
		return false;
	}
};

// const checkIfAuthenticated = ( component ) => {
// 	console.log(localStorage.getItem( "authToken" ));
// 	if( localStorage.getItem( "authToken" ) != undefined && localStorage.getItem( "authToken" ) != null ){
// 		return component;
// 	}
// 	else{
// 		return <Navigate to={`${configData.ADMIN_PREFIX}/login`}/>
// 	}
// }

const login = ( data ) => {
	return axios
		.post( configData.API_URL + "/login", data )
		.then( ( response ) => {
			if( response !== undefined ){
				let dataResponse = response.data;
				if( dataResponse.result.accessToken !== undefined && dataResponse.result.userObject !== undefined ){
					localStorage.setItem( "authToken", dataResponse.result.accessToken );
					localStorage.setItem( "user", JSON.stringify(dataResponse.result.userObject) );
					if(data.remember_me){
                        localStorage.setItem( "username", data.email );
                        localStorage.setItem( "password", data.password);
					}else{
                        localStorage.removeItem("username");
                        localStorage.removeItem("password");
					}
				}
			}
			return response.data;
		} );
};

const loginWithNeon = ( data ) => {
    return axios
        .post( configData.API_URL + "/login-with-neon", data )
        .then( ( response ) => {
            if( response !== undefined ){
                let dataResponse = response.data;
                if( dataResponse.result.accessToken !== undefined && dataResponse.result.userObject !== undefined ){
                    localStorage.setItem( "authToken", dataResponse.result.accessToken );
                    localStorage.setItem( "user", JSON.stringify(dataResponse.result.userObject) );
                    if(data.remember_me){
                        localStorage.setItem( "username", data.email );
                        localStorage.setItem( "password", data.password);
                    }
                }
            }
            return response.data;
        } );
};

const logout = () => {
	localStorage.removeItem( "authToken" );
	localStorage.removeItem( "user" );
	localStorage.removeItem( "current_params" );
    delete axios.defaults.headers.common["Authorization"];
	return true;
};

const getCurrentUser = () => {
	if( localStorage.getItem( "user" ) ){
		return JSON.parse( localStorage.getItem( "user" ) );
	}
	else{
		return null;
	}
};

const forgotPassword = ( data ) => {
	return axios
		.post( configData.API_URL + "/forgot", data )
		.then( ( response ) => {
			return response.data;
		} );
};

const resetPassword = ( data ) => {
	return axios
		.put( configData.API_URL + "/reset/"+data.token, data )
		.then( ( response ) => {
			return response.data;
		} );
};

const checkTokenStatus = ( data ) => {
    return axios.get( configData.API_URL + "/checkToken/"+data.token );
};

const AuthService = {
	checkIfAuthenticated,
	login,
	logout,
	forgotPassword,
	resetPassword,
	getCurrentUser,
    loginWithNeon,
    checkTokenStatus,
};

export default AuthService;
