import React from "react";
import configData from "../config";

const UserIndex = React.lazy(()=>import("../components/User"));
const UserCreate = React.lazy(()=>import("../components/User/create"));
const UserEdit = React.lazy(()=>import("../components/User/edit"));

const UserRoute = [
	// { path : `${configData.ADMIN_PREFIX}/users`, element : AuthService.checkIfAuthenticated( <UserIndex/> ) },
	// { path : `${configData.ADMIN_PREFIX}/users/create`, element : AuthService.checkIfAuthenticated( <UserCreate/> ) },
	// { path : `${configData.ADMIN_PREFIX}/users/:id/edit`, element : AuthService.checkIfAuthenticated( <UserEdit/> ) },
	
	{ path : `${configData.ADMIN_PREFIX}/users`, element : <UserIndex/> },
	{ path : `${configData.ADMIN_PREFIX}/users/create`, element : <UserCreate/> },
	{ path : `${configData.ADMIN_PREFIX}/users/:id/edit`, element : <UserEdit/> },
	// {
	// 	path : `${configData.ADMIN_PREFIX}/users`,
	// 	element : <UserIndex/>,
	// 	children : [
	// 		// { path : '/', element : <UserIndex/> },
	// 		{ path : 'create', element : <UserCreate/> },
	// 		{ path : ':id/edit', element : <UserEdit/> },
	// 	]
	// }
];

export default UserRoute