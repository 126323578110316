import React from "react";
import configData from "../config";

const Home = React.lazy(() => import("../components/Home"));
const ViewMember = React.lazy(() => import("../components/MemberView"));
const ChangePassword = React.lazy(() => import("../components/ChangePassword"));

const DashboardRoute = [
	// { path : `${configData.ADMIN_PREFIX}/dashboard`, element : AuthService.checkIfAuthenticated( <Home/> ) },
	{ path : `${configData.ADMIN_PREFIX}/members`, element : <Home/> },
    { path : `${configData.ADMIN_PREFIX}/member/:id/view`, element : <ViewMember/> },
	{ path : `${configData.ADMIN_PREFIX}/change-password`, element : <ChangePassword/> },
];

export default DashboardRoute
