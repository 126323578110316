// Custom Configuration file
function configData(){
	return {
		"SITE_NAME" : "CIVIC Leadership Institute",
		"SITE_URL" : window.origin,
		"ADMIN_PREFIX" : "/site-admin",
		"MEMBER_PREFIX" : "/site-member",
		"API_URL" : process.env.REACT_APP_API_URL,
		"LOGIN_WITH_URL" : process.env.REACT_APP_LOGIN_WITH_URL
	}
}

export default configData();
