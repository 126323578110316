// import logo from './logo.svg';
import './App.css';
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import React, { Fragment, Suspense, useEffect } from "react";
import IndexRoute from "./routes/index";
import configData from "./config";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import AuthService from "./services/auth.service";

function App(){
	axios.defaults.baseURL = configData.API_URL;
	if( localStorage.getItem( "authToken" ) ){
		axios.defaults.headers.common = { 'Authorization' : `${localStorage.getItem( 'authToken' )}`, 'cache-control': 'no-cache' }
	}

	const Routes = () => {
		return useRoutes( IndexRoute );
	};

	const loading = (
		<div className="loader loader">
			<span>Loading...</span>
		</div>
	);

	let navigate = useNavigate();
	let currentLocation = useLocation();

	useEffect( () => {
        axios.interceptors.response.use(response => {
            return response;
        }, error => {
            toast.dismiss();
            if (error.response.status === 401) {
                let is_member_login = (JSON.parse(localStorage.getItem("user")).accountId != null) ? true : false;
                localStorage.removeItem( "authToken" );
                localStorage.removeItem( "user" );
                delete axios.defaults.headers.common["Authorization"];
                toast.error( 'Session expired! Please login again.' );
                if(is_member_login){
                    navigate(`${configData.MEMBER_PREFIX}/login`);
                }else{
                    navigate(`${configData.ADMIN_PREFIX}/login`);
                }
            }
            return error;
        });

		if( !AuthService.checkIfAuthenticated( currentLocation ) ){
			if( currentLocation.pathname == `${configData.ADMIN_PREFIX}/forgot-password` ){
				navigate( `${configData.ADMIN_PREFIX}/forgot-password` );
			}
			else{
                if( currentLocation.pathname.match(/password\/reset/g) == null ){
                    const query = new URLSearchParams(window.location.search);
                    const code = query.get('code');
                    if(typeof code != "undefined" && code !== '' && code !== null && currentLocation.pathname.match(/login/g) !== null){
					}else{
                        if( currentLocation.pathname.match(/site-admin/g) == null ){
                            navigate( `${configData.MEMBER_PREFIX}/login` );
                        }else{
                            navigate( `${configData.ADMIN_PREFIX}/login` );
                        }
					}
                }
			}
		}
		else if( currentLocation.pathname == `/` || currentLocation.pathname == `${configData.ADMIN_PREFIX}/` || currentLocation.pathname == `${configData.MEMBER_PREFIX}/` || currentLocation.pathname == `${configData.ADMIN_PREFIX}/forgot-password` || currentLocation.pathname == `${configData.ADMIN_PREFIX}/login` || currentLocation.pathname == `${configData.MEMBER_PREFIX}/login` || currentLocation.pathname.match(/password\/reset/g)){
			navigate( `${configData.ADMIN_PREFIX}/members` );
		}

	}, [] );

	return (
		<Fragment>
			{/*{content}*/}
			<Suspense fallback={loading}>
				<Routes/>
			</Suspense>
			<ToastContainer position="top-right" newestOnTop/>
		</Fragment>
	);
}

export default App;
