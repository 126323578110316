import React from "react";
import configData from "../config";

const Login = React.lazy( () => import("../components/Auth/Login") );
const MemberLogin = React.lazy( () => import("../components/Auth/MemberLogin") );
const ForgotPassword = React.lazy( () => import("../components/Auth/ForgotPassword") );
const ResetPassword = React.lazy( () => import("../components/Auth/ResetPassword") );

const AuthRoute = [
	{ path : `${configData.ADMIN_PREFIX}/`, element : <Login/> },
	{ path : `${configData.MEMBER_PREFIX}/login`, element : <MemberLogin/> },
	{ path : `${configData.ADMIN_PREFIX}/login`, element : <Login/> },
	{ path : `${configData.ADMIN_PREFIX}/forgot-password`, element : <ForgotPassword/> },
	{ path : `${configData.ADMIN_PREFIX}/password/reset/:token`, element : <ResetPassword/> },
];

export default AuthRoute
