import AuthRoute from "./AuthRoute";
import DashboardRoute from "./DashboardRoute";
import UserRoute from "./UserRoute";

const routes = [
	...AuthRoute,
	...DashboardRoute,
	...UserRoute
];

export default routes